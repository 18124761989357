<template>
  <v-row class="inner-content">
    <v-col>

      <v-card class="pt-2">
        <v-card-text>
          <p>Use this feature to import keyword ideas from the DWH. If there are already ideas present for the chosen account and language, they will be synced with the incoming records.</p>
        </v-card-text>
      </v-card>

      <v-card class="pt-2">
        <v-card-title>
          <span>Account</span>

          <v-spacer></v-spacer>

          <GoogleAccountAutoComplete
            v-model="selectedAccountID"
            @changed="accountChanged"
            @refreshing="accountsRefreshing"
          ></GoogleAccountAutoComplete>

          <v-spacer></v-spacer>

          <v-autocomplete v-model="selectedLanguageID" :items="languages" :loading="loadingRefreshLanguages" item-text="language_iso2" item-value="languages_fk" label="Language" @change="languageChanged(selectedLanguageID)"
            prepend-icon="language">
          </v-autocomplete>

          <v-spacer></v-spacer>

          <v-btn color="warning" :loading="updating" :disabled="!updateEnabled" @click="update">Update from DWH</v-btn>

          <v-spacer></v-spacer>

        </v-card-title>

        <SystemJobsTable :headers="jobHeaders" :jobType="jobType" :updateKey="updateTableKey"></SystemJobsTable>
      </v-card>

    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'KeywordCollectorRefreshData',

  data () {
    return {
      loadingRefreshLanguages: false,
      lsSelectedAccount: 'keywordCollector_selectedAccount',
      selectedAccountID: 0,
      vertical: '',
      cmsProject: '',
      countryISO2: '',
      languages: [],
      selectedLanguageID: 0,
      selectedLanguageISO2: '',
      searchTermFilterDays: 0,
      updating: false,
      updateTableKey: 'init',
      jobType: 'Core - Keyword Collector Data Preparation',
      jobHeaders: [
        { text: 'User', value: 'user_name' },
        { text: 'Account', value: 'text1' },
        { text: 'Vertical', value: 'text2' },
        { text: 'Country', value: 'text3' },
        { text: 'Language', value: 'text4' },
        { text: 'Status', value: 'status' },
        { text: 'Entry at', value: 'entry_at_time' },
        { text: 'Completed at', value: 'completed_at_time' },
        { text: 'External message', value: 'external_message' },
      ],
    }
  },

  computed: {
    accountLanguagesEndpoint () {
      return '/a/core/account-languages?ad_network=Google&xfields=languages_fk,language_iso2&xsort=language_iso2&xper_page=5000&account_id=' + this.selectedAccountID
    },

    accountMetadataEndpoint () {
      return '/a/core/account-metadata?ad_network=Google&account_id=' + this.selectedAccountID
    },

    refreshDBEndpoint () {
      return '/a/google/accounts/' + this.selectedAccountID + '/prepare-keyword-collector-data'
    },

    updateEnabled () {
      return this.vertical !== '' && this.countryISO2 !== '' && this.languageISO2 !== ''
    }
  },

  components: {
    GoogleAccountAutoComplete: () => import('@/components/autocompletes/GoogleAccountAutoComplete.vue'),
    SystemJobsTable: () => import('@/components/tables/SystemJobsTable.vue')
  },

  methods: {
    accountChanged: function () {
      if (!this.selectedAccountID) {
        return
      }

      // save to local storage
      localStorage.setItem(this.lsSelectedAccount, JSON.stringify(this.selectedAccountID))

      // reset account metadata
      this.vertical = ''
      this.cmsProject = ''
      this.countryISO2 = ''
      this.selectedLanguageID = 0
      this.selectedLanguageISO2 = ''
      this.searchTermFilterDays = 0
      this.getAccountLanguages()
      this.getAccountMetadata()
    },

    accountsRefreshing () {
      this.selectedAccountID = 0
      this.selectedLanguageID = 0
      this.languages = []
    },

    getAccountLanguages () {
      this.loadingRefreshLanguages = true

      this.$http.get(this.accountLanguagesEndpoint).then(resp => {
        if (resp.data.data) {
          this.languages = resp.data.data
        } else {
          this.languages = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: this.accountLanguagesEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loadingRefreshLanguages = false)
    },

    getAccountMetadata () {
      this.$http.get(this.accountMetadataEndpoint).then(resp => {
        if (resp.data.data.length == 1) {
          var metaData = resp.data.data[0]
          this.vertical = metaData.vertical
          this.cmsProject = metaData.vertical_cms_project
          this.countryISO2 = metaData.country_iso2
          this.selectedLanguageID = metaData.languages_fk
          this.selectedLanguageISO2 = metaData.language_iso2
          this.searchTermFilterDays = metaData.vertical_search_term_filter_days
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: this.accountMetadataEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally()
    },

    languageChanged: function (langID) {
      if (!this.selectedLanguageID) {
        return
      }
      this.selectedLanguageISO2 = this.languages.filter(el => el.languages_fk == langID).map(val => val.language_iso2)[0]
    },

    update: function () {
      if (!this.selectedAccountID) {
        return
      }
      this.updating = true

      var refreshObj = {
        'vertical': this.vertical,
        'cmsProject': this.cmsProject,
        'countryISO2': this.countryISO2,
        'languageID': this.selectedLanguageID,
        'languageISO2': this.selectedLanguageISO2,
        'searchTermFilterDays': this.searchTermFilterDays,
      }
      var body = JSON.stringify(refreshObj)

      this.$http.post(this.refreshDBEndpoint, body).then(resp => {
        this.updateTableKey = this.$dateTime.now().toString()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.refreshDBEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.updating = false)
    },
  },

  created: function () {

    // if present, use local selected account
    if (localStorage.getItem(this.lsSelectedAccount)) {
      this.selectedAccountID = JSON.parse(localStorage.getItem(this.lsSelectedAccount))
      this.getAccountLanguages()
      this.getAccountMetadata()
    }
  },
}
</script>
